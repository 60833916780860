import Toggle from "comps/toggle"
import DropDown from "comps/dropdown"

export default function HomeTabs({ type, updateState }) {
	return (
		<div className='right'>
			<Toggle
				leftLabel='Prefixing'
				rightLabel='Extraction'
				value={type}
				checked={type === "pdf"}
				onChange={() => updateState({ type: type === "excel" ? "pdf" : "excel" })}
			/>
			<DropDown />
		</div>
	)
}
