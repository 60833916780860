import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGlobalContext } from "context"
import { handleErrors, postFile, signOut } from "api"
import jwt_decode from "jwt-decode"
// ASSETS
import { FilePond } from "react-filepond"
import "filepond/dist/filepond.min.css"

let initialStore = {
	files: [],
	response: null,
}

export default function Home() {
	const [{ files, response }, setStore] = useState(initialStore)
	const navigate = useNavigate()

	const { type, token, resetState, updateNotify } = useGlobalContext()

	useEffect(() => {
		const input = document.querySelector(".filepond--browser") || document.querySelector('input[type="file"]')

		// application/vnd.ms-excel
		if (input) {
			input.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .pdf"
		}
	}, [])

	useEffect(() => {
		if (response) {
			const blob = new Blob(
				[response],
				{
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				},
				"output.xlsx"
			)

			const link = document.createElement("a")
			link.setAttribute("download", "output.xlsx")
			link.href = window.URL.createObjectURL(blob)
			document.body.appendChild(link)
			link.click()
			link.remove()
			setStore(initialStore)
		}
	}, [response])

	useEffect(() => {
		if (token) {
			const decodedToken = jwt_decode(token)
			const date = new Date().getTime()

			if (decodedToken.exp * 1000 < date) signOut(resetState, updateNotify, navigate)
		}
		// eslint-disable-next-line
	}, [token, navigate])

	const server = useMemo(
		() => ({
			process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
				const controller = new AbortController()

				const excelExtensions = ["xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
				const fileExtension = file?.type?.split("/")[1]

				if (!token) {
					signOut(resetState, updateNotify, navigate)
				} else if (
					(type === "excel" && !excelExtensions?.includes(fileExtension)) ||
					(type === "pdf" && !file.name.endsWith(".pdf"))
				) {
					handleErrors({ response: { status: 415 } }, updateNotify, error)
				} else {
					const formData = new FormData()
					formData.append("fileupload", file, file.name)

					postFile(type === "excel" ? "prefixes" : "routes", formData, progress, load, setStore, updateNotify, error)
				}

				return {
					abort: () => {
						controller.abort()
						abort()
					},
				}
			},
		}),
		[resetState, updateNotify, navigate, type, token]
	)

	return (
		<div className='app'>
			<div className='droparea-container'>
				<FilePond
					files={files}
					onupdatefiles={(files) => setStore((prev) => ({ ...prev, files }))}
					maxFiles={1}
					server={server}
					name='file'
					labelIdle={`<span>Drop your <span>${type ?? "excel"}</span> file</span><span>or</span><span>Browse</span>`}
					stylePanelLayout='integrated'
					dropValidation='true'
					checkValidity='true'
					styleLoadIndicatorPosition='center'
					styleProgressIndicatorPosition='center'
				/>
			</div>
		</div>
	)
}
