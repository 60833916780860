import { useGlobalContext } from "context"
import HomeTabs from "./homeTabs"

export default function Header({ isHome }) {
	const { type, updateState } = useGlobalContext()

	return (
		<header>
			<div className='left'>
				<img
					src={
						isHome
							? "https://res.cloudinary.com/dniaqkd0y/image/upload/v1647597057/Antwerp/ant-small_vu7fk5.png"
							: "https://res.cloudinary.com/dniaqkd0y/image/upload/v1646314514/Antwerp/logo_uuolqf.png"
					}
					alt='Antwerp Logo'
				/>
				<h1>Antwerp Technologies | {!isHome || type === "excel" ? "Prefixing" : "Extraction"} Tool</h1>
			</div>
			{isHome && <HomeTabs {...{ type, updateState }} />}
		</header>
	)
}
