import { useLocalStorage } from "hooks"
import { useState, useContext, createContext } from "react"

export const GlobalContext = createContext()

const initialState = {
	token: "",
	type: "excel",
}

export const useGlobalContext = () => useContext(GlobalContext)

export default function GlobalProvider({ children }) {
	const [notify, updateNotify] = useState({})
	const [{ token, type }, setState] = useLocalStorage("ANT_USER", initialState)

	const updateState = (values) => setState((prev) => ({ ...prev, ...values }))

	return (
		<GlobalContext.Provider
			value={{
				type,
				token,
				notify,
				updateState,
				updateNotify,
				resetState: () => setState(initialState),
			}}>
			{children}
		</GlobalContext.Provider>
	)
}
