import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { PrivateRoute, PublicRoute } from "./checkRoute"
import GlobalProvider from "context"
//COMPS
import Layout from "comps/layout"
import Notification from "comps/notification"
//PAGES
import Home from "pages/home"
import Auth from "pages/auth"
import NotFound from "pages/notFound"

export default function BaseRouter() {
	return (
		<Router>
			<GlobalProvider>
				<Notification />
				<Layout>
					<Routes>
						<Route
							path='/'
							element={
								<PrivateRoute>
									<Home />
								</PrivateRoute>
							}
						/>
						<Route
							path='/auth'
							element={
								<PublicRoute>
									<Auth />
								</PublicRoute>
							}
						/>
						<Route path='*' element={<NotFound />} />
					</Routes>
				</Layout>
			</GlobalProvider>
		</Router>
	)
}
