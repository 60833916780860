import { useGlobalContext } from "context"
import { Navigate } from "react-router-dom"

export const PrivateRoute = ({ children }) => {
	const { token } = useGlobalContext()

	return token ? children : <Navigate to='/auth' />
}

export const PublicRoute = ({ children }) => {
	const { token } = useGlobalContext()

	return token ? <Navigate to='/' /> : children
}
