export default function Footer() {
	const year = new Date().getFullYear()
	return (
		<footer className='footer-bottom'>
			<p>Copyright &copy; {year}</p>
			<a
				href='https://wwww.antwerptechnologies.com.com'
				target='_blank'
				rel='noreferrer noopener'
				className='footer-link'>
				Antwerp Technologies
			</a>
			<p>. All rights reserved.</p>
		</footer>
	)
}
