import { useRef, useState } from "react"
import { useClickOutside } from "hooks"
import { useNavigate } from "react-router-dom"
import { useGlobalContext } from "context"
import { signOut } from "api"
import { CSSTransition } from "react-transition-group"
// ASSETS
import PdfTemplate from "assets/pdf-template.pdf"
import ExcelTemplate from "assets/excel-template.xlsx"
import { VscFilePdf } from "react-icons/vsc"
import { FiLogOut } from "react-icons/fi"
import { FiSettings } from "react-icons/fi"
import { RiFileExcel2Line } from "react-icons/ri"
import { IoCloseCircleOutline } from "react-icons/io5"

const btns = [
	{
		Icon: RiFileExcel2Line,
		download: "excel-template",
		href: ExcelTemplate,
		text: "Excel Template",
	},
	{ Icon: VscFilePdf, download: "pdf-template", href: PdfTemplate, text: "PDF Template" },
]

export default function DropDown() {
	const navigate = useNavigate()

	const childRef = useRef()
	const parentRef = useRef()

	const { resetState, updateNotify } = useGlobalContext()

	const [isOpen, setIsOpen] = useState(false)

	useClickOutside(parentRef, () => setIsOpen(false))

	return (
		<div className='dropdown' ref={parentRef}>
			<button onClick={() => setIsOpen((prev) => !prev)} className='dropdown-btn'>
				<h2>{isOpen ? "Close" : "Options"}</h2>
				{isOpen ? <IoCloseCircleOutline className='icon' /> : <FiSettings className='icon' />}
			</button>
			<CSSTransition nodeRef={childRef} in={isOpen} timeout={300} unmountOnExit classNames='dropdown-content'>
				<div className='dropdown-content' ref={childRef}>
					{btns?.map(({ Icon, download, href, text }, idx) => (
						<a key={idx} className='download-btn' download={download} href={href} onClick={() => setIsOpen(false)}>
							<p>{text}</p>
							<Icon className='icon' />
						</a>
					))}
					<button onClick={() => signOut(resetState, updateNotify, navigate)} className='sign-out-btn'>
						<p>Sign Out</p>
						<FiLogOut className='icon' />
					</button>
				</div>
			</CSSTransition>
		</div>
	)
}
