import { makeUpperCase } from "utils"

export default function InputRow({ icon, showPass, ...props }) {
	const isPassRow = props?.name.startsWith("password")

	return (
		<div className={`row ${isPassRow ? "pass-row" : "email-row"} `}>
			<h2>{makeUpperCase(props?.name)}</h2>
			<div className={`input-bar-icon ${props?.value && props?.value?.length > 0 ? "filled" : ""}`}>
				{icon}
				<input type={isPassRow ? (showPass ? "text" : "password") : "email"} {...props} required />
			</div>
		</div>
	)
}
