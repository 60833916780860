import { useLocation } from "react-router-dom"
import { pageClassName } from "utils"
import Footer from "./footer"
import Header from "./header"

export default function Layout({ children }) {
	const location = useLocation()

	return (
		<div className={`page ${pageClassName(location)}`}>
			{(location.pathname === "/" || location.pathname === "/auth") && (
				<Header isHome={location.pathname === "/"} />
			)}
			{children}
			<Footer />
		</div>
	)
}
