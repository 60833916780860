import { useEffect, useRef } from "react"
import { useGlobalContext } from "context"
import Loading from "comps/loading"
//ICONS
import { IoClose } from "react-icons/io5"
import { BiError } from "react-icons/bi"
import { BsCheck2Circle } from "react-icons/bs"

export default function Notification() {
	const notifyContainerRef = useRef(null)

	const { notify, updateNotify } = useGlobalContext()

	useEffect(() => {
		if (
			!notifyContainerRef?.current?.classList?.contains("notification-container-close") &&
			(notify?.error || notify?.success)
		) {
			setTimeout(
				() => {
					notifyContainerRef?.current?.classList.add("notification-container-close")
				},
				notify?.error ? 6000 : 2000
			)

			setTimeout(() => updateNotify({}), notify?.error ? 6300 : 2300)
		}
		// eslint-disable-next-line
	}, [notify])

	const notifyStatus = notify?.error ? "error" : "success"

	const handleClose = (e) => {
		e.preventDefault()

		notifyContainerRef?.current?.classList &&
			notifyContainerRef?.current?.classList?.length > 0 &&
			notifyContainerRef?.current?.classList.add("notification-container-close")

		setTimeout(() => updateNotify({}), 300)
	}

	return notify?.loading ? (
		<Loading />
	) : notify?.error || notify?.success ? (
		<div className={`notification-container ${notifyStatus}`} ref={notifyContainerRef} onClick={handleClose}>
			<div className='notification-card'>
				<div className='notification-content'>
					{notify?.error ? <BiError className='error-icon' /> : <BsCheck2Circle className='error-icon' />}
					<p>{notify?.error ? notify?.error : notify?.success}</p>
				</div>
				<button onClick={handleClose} className='notification-close'>
					<IoClose className='icon' />
				</button>
			</div>
		</div>
	) : null
}
