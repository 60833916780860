import axios from "axios"

const API = axios.create({ baseURL: process.env.REACT_APP_PREFIX })

API.interceptors.request.use((req) => {
	if (localStorage.getItem("ANT_USER")) {
		req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem("ANT_USER"))?.token}`
	}

	return req
})

export const handleErrors = (exception, updateNotify, sendError) => {
	let msg

	const { request, response } = exception

	if (response) {
		if (!response?.status) {
			msg =
				"Network error may have occured. Make sure to have an Internet connection, or contact IT department for support."
		} else {
			const { status } = response
			msg =
				status === 401
					? "Invalid credentials."
					: status === 404
					? "File not found, or API endpoint may have been modified."
					: [415, 400]?.includes(status)
					? "Invalid file Type."
					: status === 500
					? "Server error. Uploaded file may be corrupted."
					: "Network error may have occured. Make sure to have an Internet connection, or contact IT department for support."
		}
	} else if (request) {
		msg = "request error"
	} else {
		msg = exception.message
	}

	sendError && sendError(msg)
	updateNotify({ error: msg })
}

export const postFile = async (type, formData, progress, load, setResponse, updateNotify, sendError) => {
	const config = {
		onUploadProgress(progressEvent) {
			progress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total)
		},
		responseType: "blob",
	}

	try {
		const { data: response } = await API.post(`/${type}/generate`, formData, config)

		load("All Done and ready!")
		response && setResponse((prev) => ({ ...prev, response }))
	} catch (exception) {
		handleErrors(exception, updateNotify, sendError)
	}
}

export const signIn = async (userData, updateState, updateNotify, navigate) => {
	try {
		updateNotify({ loading: true })

		const { data } = await API.post("/users/login", userData)

		updateState({
			token: data?.data?.token,
			type: "excel",
		})
		navigate("/")
		updateNotify({ loading: false, success: "Welcome aboard!" })
	} catch (exception) {
		updateNotify({ loading: false })
		handleErrors(exception, updateNotify)
	}
}

export const signOut = async (resetState, updateNotify, navigate) => {
	try {
		resetState()
		navigate("/auth")
	} catch (exception) {
		handleErrors(exception, updateNotify)
	}
}
