export default function Toggle({ leftLabel, rightLabel, ...props }) {
	return (
		<div className='toggle-btn'>
			{leftLabel && (
				<button
					className={`toggle-label ${props?.value === "excel" ? "checked" : ""}`}
					onClick={props.checked ? props.onChange : null}>
					{leftLabel}
				</button>
			)}
			<label>
				<input type='checkbox' {...props} />
				<span className='slider' />
			</label>
			{rightLabel && (
				<button
					className={`toggle-label ${props?.value === "pdf" ? "checked" : ""}`}
					onClick={!props.checked ? props.onChange : null}>
					{rightLabel}
				</button>
			)}
		</div>
	)
}
