export const validateAuth = ({ email, password }) => {
	if (!email || !password) return "Please fill in all inputs"

	const emailReg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	if (!String(email).toLowerCase().match(emailReg))
		return "Please add a valid email address."

	const passReg = /^(.{0,7}|[^a-z]{1,}|[^A-Z]{1,}|[^\d]{1,}|[^\W]{1,})$|[\s]/
	if (String(password).match(passReg)) return "Invalid credentials"

	return
}
