export default function Loading() {
	return (
		<div className='loading-container'>
			<div className='wrapper'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 100 100'
					preserveAspectRatio='xMidYMid'>
					<g transform='translate(50 50)'>
						<g>
							<path
								d='M29.492-5.5h8v11h-8a30 30 0 0 1-4.75 11.465L30.4 22.62 22.62 30.4l-5.656-5.657A30 30 0 0 1 5.5 29.492v8h-11v-8a30 30 0 0 1-11.465-4.75L-22.62 30.4-30.4 22.62l5.657-5.656A30 30 0 0 1-29.492 5.5h-8v-11h8a30 30 0 0 1 4.75-11.465L-30.4-22.62l7.779-7.779 5.656 5.657A30 30 0 0 1-5.5-29.492v-8h11v8a30 30 0 0 1 11.465 4.75L22.62-30.4 30.4-22.62l-5.657 5.656A30 30 0 0 1 29.492-5.5M0-20a20 20 0 1 0 0 40 20 20 0 1 0 0-40'
								fill='#7f9bbb'
							/>
						</g>
					</g>
				</svg>
				<h1>Please Wait</h1>
			</div>
		</div>
	)
}
