import { useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { signIn } from "api"
import { useGlobalContext } from "context"
import { validateAuth } from "utils/validate"
// COMPS
import InputRow from "comps/inpurRow"
import PassIcon from "comps/inpurRow/PassIcon"
//ICONS
import { BiUser } from "react-icons/bi"
import { BsArrowRightCircle } from "react-icons/bs"
import { HiOutlineMailOpen } from "react-icons/hi"

let initialStore = {
	email: "",
	password: "",
	showPass: false,
}

export default function Auth() {
	const navigate = useNavigate()

	const { updateState, updateNotify } = useGlobalContext()
	const [{ email, password, showPass }, setStore] = useState(initialStore)

	const handleChange = (e) => setStore((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }))
	const updateStore = (values) => setStore((prev) => ({ ...prev, ...values }))

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault()
			if (!email || !password) return

			let error = validateAuth({ email, password })
			if (error) return updateNotify({ error })

			signIn({ email, password }, updateState, updateNotify, navigate)
		},
		[navigate, updateState, updateNotify, email, password]
	)

	return (
		<>
			<div className='wrapper'>
				<section className='top'>
					<BiUser className='icon' />
					<h1>SIGN IN</h1>
				</section>
				<form onSubmit={handleSubmit}>
					<div className='form-rows'>
						<InputRow
							icon={<HiOutlineMailOpen className='icon' />}
							showPass={showPass}
							name='email'
							value={email}
							onChange={handleChange}
						/>
						<InputRow
							icon={<PassIcon showPass={showPass} togglePass={() => updateStore({ showPass: !showPass })} />}
							showPass={showPass}
							name='password'
							value={password}
							onChange={handleChange}
							onKeyDown={(e) => e?.key === "Enter" && handleSubmit(e)}
						/>
					</div>
					<button type='submit' className='submit-btn'>
						<BsArrowRightCircle className='icon' />
					</button>
				</form>
			</div>
		</>
	)
}
