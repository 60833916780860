import { useEffect } from "react"

export default function useClickOutside(ref, onClickOutside) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) onClickOutside()
		}
		document.addEventListener("mousedown", handleClickOutside)
		return () => document.removeEventListener("mousedown", handleClickOutside)
	}, [ref, onClickOutside])
}
