export const pageClassName = (location) => {
	return `${
		location.pathname === "/"
			? "home"
			: location.pathname === "/auth"
			? "auth"
			: "notfound"
	}-page`
}

export const makeUpperCase = (word) => {
	return word[0].toUpperCase() + word.slice(1, word.length)
}
